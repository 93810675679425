import ConfigCollection from '../../classes/ConfigCollection';

const state = {
  ...ConfigCollection.state,
  configs: {},
};

const getters = {};

const actions = {
  ...ConfigCollection.actions,

  fetchConfigs: async function ({ commit, dispatch }) {
    try {
      const res = await dispatch('getLoginConfigs', { __native: true });
      const configs = {};

      res.data.result.forEach(({ id, value }) => {
        configs[id] = value;
      });
      commit('setConfigs', configs);
    } catch (e) {
      console.log(e);
    }
  },
};

const mutations = {
  ...ConfigCollection.mutations,

  setConfigs: function (state, data) {
    state.configs = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
