import EventCollection from '@/classes/EventCollection';

// initial state
const state = {
  ...EventCollection.state,
};

// getters
const getters = {};

// actions
const actions = {
  ...EventCollection.actions,

  async fetch({ dispatch }, filter) {
    await dispatch('find', { filter });
  },
  async createElement({ dispatch }, newItem) {
    await dispatch('create', { data: newItem, __native: true });
  },
  async editElement({ dispatch }, item) {
    await dispatch('patchAttributes', {
      id: item.id,
      data: item,
      __native: true,
    });
  },
  async deleteElement({ dispatch }, data) {
    await dispatch('deleteById', { id: data.id, __native: true });
  },

  setFilter({ commit }, filter) {
    commit('setFilter', filter);
  },
};

// mutations
const mutations = {
  ...EventCollection.mutations,

  setFilter(state, filter) {
    state.filter = filter;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
