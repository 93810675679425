/* eslint-disable */

import Vue from 'vue';
import { request, getOptions } from './authJwt';

// State
export const state = {
  User: [],
  className: 'UserInfo',
};

// Getters
export const getters = {};

// Actions
export const actions = {
  async LogInByJwt({ commit }, parameters) {
    const config = parameters;

    const path = parameters.channel ? `/jwt-${parameters.channel}` : `/jwt`;
    let body;
    const queryParameters = {};
    const form = {};

    if (parameters.token !== undefined) {
      queryParameters.token = parameters.token;
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(parameterName => {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }

    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },
};

// Mutations
export const mutations = {
  setElements(state, elements) {
    state.elements = elements && elements.data ? elements.data : elements;
  },

  setElement(state, element) {
    const index = _.findIndex(state.elements, { id: element.id });

    if (index < 0) {
      state.elements.push(element);
    } else {
      Vue.set(state.elements, index, element);
    }
  },

  addElement(state, element) {
    state.elements.push(element);
  },

  deleteElement(state, id) {
    const index = _.findIndex(state.elements, { id });
    if (index > 0) {
      state.element.splice(index, 1);
    }
  },

  setCount(state, count) {
    state.count = count;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
