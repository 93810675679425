import store from '@/store/index.js';
import _ from 'lodash';

export const registerServiceWorker = async () => {
  const versions = await store.dispatch('info/fetchVersions');
  if (_.get(versions, 'notify.installed', false)) {
    parent.postMessage(JSON.stringify({ eventType: 'registerServiceWorker' }), '*');
    return true;
  } else {
    return false;
  }
};

export default { registerServiceWorker };
