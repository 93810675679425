<template>
  <div class="q-loader-wrapper">
    <div class="q-loader-spinner"></div>
  </div>
</template>

<script>
  export default {
    name: 'Loader',
  };
</script>

<style scoped>
  @keyframes q-rotate {
    from {
      transform: rotate(0deg) scale3d(1, 1, 1);
    }
    50% {
      transform: rotate(180deg) scale3d(1.05, 1.05, 1.05);
    }
    to {
      transform: rotate(360deg) scale3d(1, 1, 1);
    }
  }

  .q-loader-wrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
  }

  .q-loader-spinner {
    box-sizing: border-box;
    width: 42px;
    height: 42px;
    border: 11px solid #0064c8;
    border-left-color: transparent;
    border-right-color: transparent;
    border-radius: 50%;
    animation: q-rotate 1s infinite ease-in;
    z-index: 9999;
  }
</style>
