import NotifyCollection from '../../classes/NotifyServiceCollection';
import { registerServiceWorker } from '@/plugins/notify.js';

const state = {
  ...NotifyCollection.state,
};

const getters = {};

const actions = {
  ...NotifyCollection.actions,
  async initPushService({ commit, dispatch }, params) {
    const isSWCanByRegistered = await registerServiceWorker();
    if (isSWCanByRegistered) {
      window.addEventListener('message', event => {
        let eventObj;
        try {
          if (typeof event === 'string') {
            if (event[0] === '{') {
              eventObj = JSON.parse(event.data);
            } else {
              return;
            }
          } else {
            if (typeof event === 'object') {
              eventObj = event.data;
            } else {
              return;
            }
          }
        } catch (e) {
          console.log('json parse failed');
          return;
        }

        if (eventObj.eventType === 'swRegistered') {
          try {
            dispatch('subscribe', {
              data: {
                token: eventObj.eventData.token,
                userId: params.userId,
                type: 0,
              },
            });
          } catch (e) {
            console.log('subscribe failed: ');
          }
        }
      });
    }
  },
};

const mutations = {
  ...NotifyCollection.mutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
