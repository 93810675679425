import { get } from 'lodash';
import ConfigCollection from '../../classes/ConfigCollection';

const state = {
  ...ConfigCollection.state,
  configs: {},
};

const getters = {
  isLogoutDisabled: function (state) {
    return get(state, 'configs.disable_chat_logout', false);
  },

  isChips: function (state) {
    return get(state, 'configs.chatChips', false);
  },

  tutorCommand: function (state) {
    return get(state, 'configs.tutorCommand', null);
  },
};

const actions = {
  ...ConfigCollection.actions,

  fetchConfigs: async function ({ commit, dispatch }) {
    try {
      const res = await dispatch('getBuiltinChatConfigs', { __native: true });
      const configs = {};

      res.data.result.forEach(({ id, value }) => {
        try {
          configs[id] = JSON.parse(value);
        } catch (e) {
          configs[id] = value;
        }
      });
      commit(
        'language/setConfigsLanguage',
        'defaultLanguage' in configs ? configs.defaultLanguage : null,
        { root: true }
      );
      commit('setConfigs', configs);
      console.log('configs:', JSON.parse(JSON.stringify(configs)));
      return configs;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

const mutations = {
  ...ConfigCollection.mutations,

  setConfigs: function (state, data) {
    state.configs = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
