import Vue from 'vue';
/* eslint-disable */
import { request, getOptions, AccessToken } from './api';

export const state = {
  elements: [],
  count: null,
  className: 'Session',
};

export const getters = {};

export const addEventListener = async ({ commit }, parameters) => {
  const config = parameters;
  let path = '/Sessions/{id}/addEventListener';
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace('{id}', `${parameters['id']}`);
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'));
  }
  if (parameters['eventId'] !== undefined) {
    queryParameters['eventId'] = parameters['eventId'];
  }
  if (parameters['agentId'] !== undefined) {
    queryParameters['agentId'] = parameters['agentId'];
  }
  if (parameters['params'] !== undefined) {
    queryParameters['params'] = parameters['params'];
  }
  if (parameters['req'] !== undefined) {
    queryParameters['req'] = parameters['req'];
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName];
    });
  }
  return request('post', getOptions().domain + path, body, queryParameters, form, config);
};
export const setParameter = async ({ commit }, parameters) => {
  const config = parameters;
  let path = '/Sessions/{id}/setParameter';
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace('{id}', `${parameters['id']}`);
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'));
  }
  if (parameters['parameterId'] !== undefined) {
    queryParameters['parameterId'] = parameters['parameterId'];
  }
  if (parameters['value'] !== undefined) {
    queryParameters['value'] = parameters['value'];
  }
  if (parameters['initiator'] !== undefined) {
    queryParameters['initiator'] = parameters['initiator'];
  }
  if (parameters['options'] !== undefined) {
    queryParameters['options'] = parameters['options'];
  }
  if (parameters['req'] !== undefined) {
    queryParameters['req'] = parameters['req'];
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName];
    });
  }
  return request('post', getOptions().domain + path, body, queryParameters, form, config);
};
export const setResult = async ({ commit }, parameters) => {
  const config = parameters;
  let path = '/Sessions/{id}/setResult';
  let body;
  let queryParameters = {};
  let form = {};
  path = path.replace('{id}', `${parameters['id']}`);
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'));
  }
  if (parameters['kbId'] !== undefined) {
    queryParameters['kbId'] = parameters['kbId'];
  }
  if (parameters['percent'] !== undefined) {
    queryParameters['percent'] = parameters['percent'];
  }
  if (parameters['initiator'] !== undefined) {
    queryParameters['initiator'] = parameters['initiator'];
  }
  if (parameters['req'] !== undefined) {
    queryParameters['req'] = parameters['req'];
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName];
    });
  }
  return request('post', getOptions().domain + path, body, queryParameters, form, config);
};

// actions
export const actions = {
  addEventListener,
  setParameter,
  setResult,

  async __get__platform({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/platform';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['refresh'] !== undefined) {
      queryParameters['refresh'] = parameters['refresh'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __get__user({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/user';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['refresh'] !== undefined) {
      queryParameters['refresh'] = parameters['refresh'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __get__operator({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/operator';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['refresh'] !== undefined) {
      queryParameters['refresh'] = parameters['refresh'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __findById__sessionEvents({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionEvents/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __destroyById__sessionEvents({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionEvents/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __updateById__sessionEvents({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionEvents/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('put', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __findById__sessionListeners({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionListeners/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __destroyById__sessionListeners({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionListeners/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __updateById__sessionListeners({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionListeners/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('put', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __findById__sessionParameters({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionParameters/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __destroyById__sessionParameters({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionParameters/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __updateById__sessionParameters({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionParameters/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('put', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __findById__sessionAgents({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionAgents/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __destroyById__sessionAgents({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionAgents/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __updateById__sessionAgents({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionAgents/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('put', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __findById__sessionResults({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionResults/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __destroyById__sessionResults({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionResults/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __updateById__sessionResults({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionResults/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('put', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __findById__sessionUIs({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionUIs/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __destroyById__sessionUIs({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionUIs/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __updateById__sessionUIs({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionUIs/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('put', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __findById__sessionAskedParameters({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionAskedParameters/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __destroyById__sessionAskedParameters({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionAskedParameters/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __updateById__sessionAskedParameters({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionAskedParameters/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('put', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __get__perceptron({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/perceptron';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['refresh'] !== undefined) {
      queryParameters['refresh'] = parameters['refresh'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __findById__tasks({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/tasks/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __destroyById__tasks({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/tasks/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __updateById__tasks({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/tasks/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('put', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __findById__prompts({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/prompts/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __destroyById__prompts({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/prompts/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __updateById__prompts({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/prompts/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('put', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __get__sessionEvents({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionEvents';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __create__sessionEvents({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionEvents';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __delete__sessionEvents({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionEvents';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __count__sessionEvents({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionEvents/count';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __get__sessionListeners({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionListeners';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __create__sessionListeners({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionListeners';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __delete__sessionListeners({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionListeners';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __count__sessionListeners({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionListeners/count';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __get__sessionParameters({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionParameters';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __create__sessionParameters({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionParameters';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __delete__sessionParameters({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionParameters';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __count__sessionParameters({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionParameters/count';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __get__sessionAgents({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionAgents';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __create__sessionAgents({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionAgents';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __delete__sessionAgents({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionAgents';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __count__sessionAgents({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionAgents/count';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __get__sessionResults({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionResults';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __create__sessionResults({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionResults';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __delete__sessionResults({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionResults';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __count__sessionResults({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionResults/count';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __get__sessionUIs({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionUIs';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __create__sessionUIs({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionUIs';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __delete__sessionUIs({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionUIs';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __count__sessionUIs({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionUIs/count';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __get__sessionAskedParameters({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionAskedParameters';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __create__sessionAskedParameters({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionAskedParameters';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __delete__sessionAskedParameters({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionAskedParameters';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __count__sessionAskedParameters({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sessionAskedParameters/count';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __get__tasks({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/tasks';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __create__tasks({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/tasks';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __delete__tasks({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/tasks';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __count__tasks({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/tasks/count';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __get__prompts({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/prompts';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __create__prompts({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/prompts';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __delete__prompts({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/prompts';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __count__prompts({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/prompts/count';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async create({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('post', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'post',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('addElement', data);
    }
  },

  async patchOrCreate({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('patch', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'patch',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setElement', data);
    }
  },

  async replaceOrCreate({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/replaceOrCreate';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('post', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'post',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setElement', data);
    }
  },

  async upsertWithWhere({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/upsertWithWhere';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('post', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'post',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setElement', data);
    }
  },

  async exists({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/exists';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['id'] !== undefined) {
      queryParameters['id'] = parameters['id'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async findById({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async replaceById({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/replace';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('post', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'post',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setElement', data);
    }
  },

  async find({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('get', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'get',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setElements', data);
    }
  },

  async findOne({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/findOne';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async updateAll({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/update';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async deleteById({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('delete', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      await request('delete', getOptions().domain + path, body, queryParameters, form, config);
      commit('deleteElement', parameters.id); //hardcode for deleteId
    }
  },

  async count({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/count';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('get', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'get',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setCount', data);
    }
  },

  async patchAttributes({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('patch', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'patch',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setElement', data);
    }
  },

  async createChangeStream({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/change-stream';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async removeEventListener({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/removeEventListener';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['eventId'] !== undefined) {
      queryParameters['eventId'] = parameters['eventId'];
    }
    if (parameters['agentId'] !== undefined) {
      queryParameters['agentId'] = parameters['agentId'];
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async removeAllEventListeners({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/removeAllEventListeners';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['eventId'] !== undefined) {
      queryParameters['eventId'] = parameters['eventId'];
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async triggerEvent({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/triggerEvent';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['eventId'] !== undefined) {
      queryParameters['eventId'] = parameters['eventId'];
    }
    if (parameters['params'] !== undefined) {
      queryParameters['params'] = parameters['params'];
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async getParameters({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/getParameters';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async getParameter({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/getParameter';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['parameterId'] !== undefined) {
      queryParameters['parameterId'] = parameters['parameterId'];
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async clearParameters({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/clearParameters';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async clearParameter({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/clearParameter';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['parameterId'] !== undefined) {
      queryParameters['parameterId'] = parameters['parameterId'];
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async enqueueAgent({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/enqueueAgent';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['agentId'] !== undefined) {
      queryParameters['agentId'] = parameters['agentId'];
    }
    if (parameters['params'] !== undefined) {
      queryParameters['params'] = parameters['params'];
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async setKB({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/setKB';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['kbId'] !== undefined) {
      queryParameters['kbId'] = parameters['kbId'];
    }
    if (parameters['percent'] !== undefined) {
      queryParameters['percent'] = parameters['percent'];
    }
    if (parameters['initiator'] !== undefined) {
      queryParameters['initiator'] = parameters['initiator'];
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async getResults({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/getResults';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async askParameter({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/askParameter';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['parameterId'] !== undefined) {
      queryParameters['parameterId'] = parameters['parameterId'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters['initiator'] !== undefined) {
      queryParameters['initiator'] = parameters['initiator'];
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async answerParameter({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/answerParameter';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['parameterId'] !== undefined) {
      queryParameters['parameterId'] = parameters['parameterId'];
    }
    if (parameters['value'] !== undefined) {
      queryParameters['value'] = parameters['value'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async showInfo({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/showInfo';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type'];
    }
    if (parameters['data'] !== undefined) {
      queryParameters['data'] = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async sendProgress({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/sendProgress';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['text'] !== undefined) {
      queryParameters['text'] = parameters['text'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async setState({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/setState';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['state'] !== undefined) {
      queryParameters['state'] = parameters['state'];
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async setPlatform({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/setPlatform';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['platformId'] !== undefined) {
      queryParameters['platformId'] = parameters['platformId'];
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async setOperator({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/setOperator';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['operatorId'] !== undefined) {
      queryParameters['operatorId'] = parameters['operatorId'];
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async createTestCase({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/createTestCase';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['name'] !== undefined) {
      queryParameters['name'] = parameters['name'];
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async getAgentPrompt({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/{id}/getAgentPrompt';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['agentId'] !== undefined) {
      queryParameters['agentId'] = parameters['agentId'];
    }
    if (parameters['promptType'] !== undefined) {
      queryParameters['promptType'] = parameters['promptType'];
    }
    if (parameters['nullIfNotExist'] !== undefined) {
      queryParameters['nullIfNotExist'] = parameters['nullIfNotExist'];
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async requestLogin({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/login/{userId}/{platformId}/{sessionId}';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['userId'] !== undefined) {
      queryParameters['userId'] = parameters['userId'];
    }
    if (parameters['platformId'] !== undefined) {
      queryParameters['platformId'] = parameters['platformId'];
    }
    if (parameters['sessionId'] !== undefined) {
      queryParameters['sessionId'] = parameters['sessionId'];
    }
    if (parameters['res'] !== undefined) {
      queryParameters['res'] = parameters['res'];
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async loginInPlatform({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/loginInPlatform';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['req'] !== undefined) {
      queryParameters['req'] = parameters['req'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async closeSessions({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/closeSessions';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async createSession({ commit }, parameters) {
    const config = parameters;
    let path = '/Sessions/createSession';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },
};

// mutations
export const mutations = {
  setElements(state, elements) {
    state.elements = elements && elements.data ? elements.data : elements;
  },
  setElement(state, element) {
    let index = _.findIndex(state.elements, { id: element.id });
    if (index < 0) {
      state.elements.push(element);
    } else {
      Vue.set(state.elements, index, element);
    }
  },
  addElement(state, element) {
    state.elements.push(element);
  },
  deleteElement(state, id) {
    let index = _.findIndex(state.elements, { id });
    if (index > 0) state.element.splice(index, 1);
  },
  setCount(state, count) {
    state.count = count;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
