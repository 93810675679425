import Vue from 'vue';
/* eslint-disable */
import { request, getOptions, AccessToken } from './api';

export const state = {
  elements: [],
  count: null,
  className: 'User',
};

export const getters = {};

// actions
export const actions = {
  async __findById__accessTokens({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/accessTokens/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __destroyById__accessTokens({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/accessTokens/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __updateById__accessTokens({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/accessTokens/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('put', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __findById__roles({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/roles/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __destroyById__roles({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/roles/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __updateById__roles({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/roles/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('put', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __link__roles({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/roles/rel/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('put', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __unlink__roles({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/roles/rel/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __exists__roles({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/roles/rel/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('head', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __findById__platforms({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/platforms/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __destroyById__platforms({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/platforms/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __updateById__platforms({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/platforms/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('put', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __link__platforms({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/platforms/rel/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('put', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __unlink__platforms({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/platforms/rel/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __exists__platforms({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/platforms/rel/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('head', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __findById__userPlatforms({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/userPlatforms/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __destroyById__userPlatforms({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/userPlatforms/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __updateById__userPlatforms({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/userPlatforms/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('put', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __findById__sessions({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/sessions/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __destroyById__sessions({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/sessions/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __updateById__sessions({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/sessions/{fk}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    path = path.replace('{fk}', `${parameters['fk']}`);
    if (parameters['fk'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: fk'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('put', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __get__accessTokens({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/accessTokens';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __create__accessTokens({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/accessTokens';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __delete__accessTokens({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/accessTokens';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __count__accessTokens({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/accessTokens/count';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __get__roles({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/roles';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __create__roles({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/roles';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __delete__roles({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/roles';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __count__roles({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/roles/count';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __get__platforms({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/platforms';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __create__platforms({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/platforms';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __delete__platforms({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/platforms';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __count__platforms({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/platforms/count';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __get__userPlatforms({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/userPlatforms';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __create__userPlatforms({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/userPlatforms';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __delete__userPlatforms({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/userPlatforms';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __count__userPlatforms({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/userPlatforms/count';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __get__sessions({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/sessions';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __create__sessions({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/sessions';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __delete__sessions({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/sessions';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('delete', getOptions().domain + path, body, queryParameters, form, config);
  },

  async __count__sessions({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/sessions/count';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async create({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('post', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'post',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('addElement', data);
    }
  },

  async patchOrCreate({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('patch', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'patch',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setElement', data);
    }
  },

  async replaceOrCreate({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/replaceOrCreate';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('post', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'post',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setElement', data);
    }
  },

  async upsertWithWhere({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/upsertWithWhere';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('post', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'post',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setElement', data);
    }
  },

  async exists({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/exists';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['id'] !== undefined) {
      queryParameters['id'] = parameters['id'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async findById({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async replaceById({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/replace';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('post', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'post',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setElement', data);
    }
  },

  async find({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('get', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'get',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setElements', data);
    }
  },

  async findOne({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/findOne';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async updateAll({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/update';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async deleteById({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('delete', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      await request('delete', getOptions().domain + path, body, queryParameters, form, config);
      commit('deleteElement', parameters.id); //hardcode for deleteId
    }
  },

  async count({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/count';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('get', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'get',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setCount', data);
    }
  },

  async patchAttributes({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('patch', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'patch',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setElement', data);
    }
  },

  async createChangeStream({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/change-stream';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async login({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/login';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['credentials'] !== undefined) {
      body = parameters['credentials'];
    }
    if (parameters['include'] !== undefined) {
      queryParameters['include'] = parameters['include'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async logout({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/logout';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['access_token'] !== undefined) {
      queryParameters['access_token'] = parameters['access_token'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('all', getOptions().domain + path, body, queryParameters, form, config);
  },

  async verify({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/verify';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['verifyOptions'] !== undefined) {
      queryParameters['verifyOptions'] = parameters['verifyOptions'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async confirm({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/confirm';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['uid'] !== undefined) {
      queryParameters['uid'] = parameters['uid'];
    }
    if (parameters['token'] !== undefined) {
      queryParameters['token'] = parameters['token'];
    }
    if (parameters['redirect'] !== undefined) {
      queryParameters['redirect'] = parameters['redirect'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async resetPassword({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/reset';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['options'] !== undefined) {
      body = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async changePassword({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/change-password';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['id'] !== undefined) {
      queryParameters['id'] = parameters['id'];
    }
    if (parameters['oldPassword'] !== undefined) {
      queryParameters['oldPassword'] = parameters['oldPassword'];
    }
    if (parameters['newPassword'] !== undefined) {
      queryParameters['newPassword'] = parameters['newPassword'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async setPassword({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/reset-password';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['id'] !== undefined) {
      queryParameters['id'] = parameters['id'];
    }
    if (parameters['newPassword'] !== undefined) {
      queryParameters['newPassword'] = parameters['newPassword'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async createSession({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/createSession';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['defaults'] !== undefined) {
      queryParameters['defaults'] = parameters['defaults'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async triggerSessionEvent({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/triggerSessionEvent';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['sessionId'] !== undefined) {
      queryParameters['sessionId'] = parameters['sessionId'];
    }
    if (parameters['eventId'] !== undefined) {
      queryParameters['eventId'] = parameters['eventId'];
    }
    if (parameters['params'] !== undefined) {
      queryParameters['params'] = parameters['params'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async setLoginToken({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/setLoginToken';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['platformId'] !== undefined) {
      queryParameters['platformId'] = parameters['platformId'];
    }
    if (parameters['loginToken'] !== undefined) {
      queryParameters['loginToken'] = parameters['loginToken'];
    }
    if (parameters['loginData'] !== undefined) {
      queryParameters['loginData'] = parameters['loginData'];
    }
    if (parameters['sessionId'] !== undefined) {
      queryParameters['sessionId'] = parameters['sessionId'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async bindBotChannel({ commit }, parameters) {
    const config = parameters;
    let path = '/Users/{id}/bindBotChannel';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['channel'] !== undefined) {
      queryParameters['channel'] = parameters['channel'];
    }
    if (parameters['userIdInChannel'] !== undefined) {
      queryParameters['userIdInChannel'] = parameters['userIdInChannel'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },
};

// mutations
export const mutations = {
  setElements(state, elements) {
    state.elements = elements && elements.data ? elements.data : elements;
  },
  setElement(state, element) {
    let index = _.findIndex(state.elements, { id: element.id });
    if (index < 0) {
      state.elements.push(element);
    } else {
      Vue.set(state.elements, index, element);
    }
  },
  addElement(state, element) {
    state.elements.push(element);
  },
  deleteElement(state, id) {
    let index = _.findIndex(state.elements, { id });
    if (index > 0) state.element.splice(index, 1);
  },
  setCount(state, count) {
    state.count = count;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
