import Vue from 'vue';
import SessionEventCollection from '@/classes/SessionEventCollection';
import { addEventListener, setParameter, setResult } from '@/classes/SessionCollection';

// initial state
const state = {
  activeDebugToolbar: false,
  activeSidebar: {},

  session: {},

  sessionEvents: [],
  sessionParameters: [],
  sessionAgents: [],
  sessionListeners: [],
  sessionResults: [],
  sessionUIs: [],
  sessionAskedParameters: [],
  tasks: [],
  prompts: [],
};

// getters
const getters = {
  session: state => state.session,
};

// actions
const actions = {
  createEvent: SessionEventCollection.actions.create,
  addEventListener: addEventListener,
  setParameter: setParameter,
  setResult: setResult,

  saveSession({ commit }, session) {
    commit('spreadSession', session);
  },

  setDebugToolbarMode({ commit }, value) {
    commit('setDebugToolbarMode', value);
  },
  setDebugSidebar({ commit }, value) {
    commit('setDebugToolbarMode', value.activeMode);
    commit('setActiveSidebar', value.sidebar);
  },

  addEvent({ commit }, value) {
    commit('upsertElement', {
      name: 'sessionEvents',
      element: value,
    });
  },
  addParameter({ commit }, value) {
    commit('upsertElement', {
      name: 'sessionParameters',
      element: value,
    });
  },
  addAgent({ commit }, value) {
    commit('upsertElement', {
      name: 'sessionAgents',
      element: value,
    });
  },
  addListener({ commit }, value) {
    commit('upsertElement', {
      name: 'sessionListeners',
      element: value,
    });
  },
  addResult({ commit }, value) {
    commit('upsertElement', {
      name: 'sessionResults',
      element: value,
    });
  },
  addUI({ commit }, value) {
    commit('upsertElement', {
      name: 'sessionUIs',
      element: value,
    });
  },
  addAskedParameter({ commit }, value) {
    commit('upsertElement', {
      name: 'sessionAskedParameters',
      element: value,
    });
  },
  addTask({ commit }, value) {
    commit('upsertElement', {
      name: 'tasks',
      element: value,
    });
  },
  addPrompt({ commit }, value) {
    commit('upsertElement', {
      name: 'prompts',
      element: value,
    });
  },
};

// mutations
const mutations = {
  setDebugToolbarMode(state, value) {
    state.activeDebugToolbar = value;
  },
  setActiveSidebar(state, value) {
    state.activeSidebar = value;
  },
  spreadSession(state, session) {
    state.session = session;

    // state.sessionEvents = session.sessionEvents.splice(0,3);
    state.sessionEvents = (session.sessionEvents || []).reverse();
    state.sessionParameters = session.sessionParameters || [];
    state.sessionAgents = session.sessionAgents || [];
    state.sessionListeners = session.sessionListeners || [];
    state.sessionResults = session.sessionResults || [];
    state.sessionUIs = session.sessionUIs || [];
    state.sessionAskedParameters = session.sessionAskedParameters || [];
    state.tasks = session.tasks || [];
    state.prompts = session.prompts || [];
  },
  upsertElement(state, { name, element }) {
    const index = (state[name] || []).findIndex(item => item.id === element.id);

    if (index >= 0) {
      Vue.set(state[name], index, element);
    } else {
      state[name].unshift(element);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
