import sanitizeHTML from 'sanitize-html';

import { request } from '../../classes/api';

const default_welcome_text = {
  title: 'Я – цифровой помощник',
  text: '<b>Я умею:</b><br/><ul><li>отвечать на часто задаваемые вопросы;</li><li>найти документ (инструкцию) на заданную тему;</li><li>узнать статус заявки;</li><li>работать с основными данными (например, изменить название поставщика);</li><li>разрешить проблемы с логином в корпоративные системы;</li> <li>создать заказ;</li><li>и многое другое...</li></ul>',
};

const state = {
  features: '',
};

const getters = {
  welcomeText: function (state) {
    const { title = '', text = '' } = state.features || {};

    return {
      title: sanitizeHTML(title, { allowedTags: ['b', 'i', 'em', 'strong'] }),
      text: sanitizeHTML(text, { allowedTags: ['b', 'i', 'em', 'strong', 'br', 'ul', 'li'] }),
    };
  },
};

const actions = {
  getWelcomeText: async function ({ commit }) {
    const baseRoot = `${window.location.protocol}//${window.location.host}`;
    const path = '/core/api/Configs/getWelcomeMessage';
    const result = await request('get', baseRoot + path, {}, {}, {});

    if (result && result.data && result.data.result) {
      let res = null;
      try {
        // console.log(result.data.result);
        res = JSON.parse(result.data.result);
      } catch (e) {
        console.log(e);
      }
      commit('setWelcomeText', res);
    } else {
      commit('setWelcomeText', default_welcome_text);
    }
  },
};

const mutations = {
  setWelcomeText: function (state, data) {
    state.features = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
